<template>
  <article>
    <AboutContent class="about" />
  </article>
</template>

<script>
import head from './head'
const AboutContent = () => import('@/components/AboutContent')

export default {
  components: {
    AboutContent
  },

  head: head
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

.about {
  display: flex;
  align-items: center;
  height: 100vh;
}
</style>
