import url from '@/resources/_url'

const head = {
  title: {
    inner: 'เกี่ยวกับ TrueCoffee GO แบรนด์กาแฟน้องใหม่'
  },
  meta: [
    {
      id: 'description',
      name: 'description',
      content: 'TrueCoffee GO แบรนด์กาแฟน้องใหม่ เลือกใช้เมล็ดการแฟที่ปลูกในไทยที่มีความพิเศษและเอกลักษณ์เฉพาะตัวไม่เหมือนใคร บอกเลยว่าคอกาแฟต้องเลิฟแน่นอน'
    },
    {
      id: 'og:url',
      name: 'og:url',
      content: `${url.mainUrl}/about`
    },
    {
      id: 'og:title',
      name: 'og:title',
      content: 'เกี่ยวกับ TrueCoffee GO แบรนด์กาแฟน้องใหม่'
    },
    {
      id: 'og:description',
      name: 'og:description',
      content: 'TrueCoffee GO แบรนด์กาแฟน้องใหม่ เลือกใช้เมล็ดการแฟที่ปลูกในไทยที่มีความพิเศษและเอกลักษณ์เฉพาะตัวไม่เหมือนใคร บอกเลยว่าคอกาแฟต้องเลิฟแน่นอน'
    },
    {
      id: 'og:image',
      name: 'og:image',
      content: '/meta-image.png'
    }
  ],
  link: [
    {
      id: 'canonical',
      rel: 'canonical',
      href: `${url.mainUrl}/about`
    }
  ]
}

export default head
